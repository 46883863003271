<template>
 
  <div id="myprofilepages" class = "pagefixedwidth">
    <TopBar   simple hidedropdown>
      <template v-slot:title>
        My profile
      </template>
     
    </TopBar>
    <main class="main-wrapper cockpitview">
     <div onsubmit="return false" id="profileform">

      <div class="form-group mt-2">
        <center> 
          <a data-toggle="tooltip" data-placement="top" title="" id="profileuploader" class="thumbnail btn-file" style="position: relative;" data-original-title="Click to update profile picture" :class="{hide:cropping==true}"  @click.prevent="$refs.profileImageuploader.click()">
            
            <base-avatar :loading = "cropping" :imgsrc = "profile.profilepic" :name = "profile.firstname" :height= "150" :width = "150" :inprofile="true"> </base-avatar>

          </a>  

          <a href="#" class ="imgloader" :class="{hide:cropping==false}">
             <span class ="m-auto" >
               <i class="fa fa-circle-o-notch fa-spin m-auto" style="font-size: 52px;"/>
            </span> 
          </a>
          
          <div class="progress" v-if="uploadedsize > 1 && uploadedsize < 100">
            <div id="uploader" class="progress-bar" role="progressbar"  v-bind:style="'width: ' + uploadedsize + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <br>
          <input style="display: none;" type="file" name="uploadedprofileimage" accept="image/*" @change="onfileselected" ref="profileImageuploader" @click="fileclicked"/>
          

          <p class="text-danger uploaderror" v-if="uploaderror=='invalid'">File is not a valid image</p> 
          <p class="text-danger uploaderror" v-if="uploaderror=='toolarge'">Image must be below 10mb</p>
          <p class="text-danger uploaderror" v-if="uploaderror=='uploadfailed'">File upload failed</p>              

        </center>           
      </div>
      <div class="form-group">
        <base-input  hasConfirmImg label = "First name" :text="profile.firstname"   @input="value => { profile.firstname = value }" @change ="doupdate('firstname', $event)" type="text" id="firstname" maxlength="70" placeholder="First Name" onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'First Name'"  :name="profile.firstname">
          <template v-slot:error>
            <span class="text-danger" v-if="profile.firstname == ''">First name is required</span>
          </template>
        </base-input>
        
      </div>

      <div class="form-group">
        <base-input  hasConfirmImg label = "Last name" :text="profile.lastname"   @input="value => { profile.lastname = value }" @change ="doupdate('lastname', $event)" type="text" id="lastname" maxlength="70" placeholder="Last Name" onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Last Name'"  :name="profile.firstname">
          <template v-slot:error>
            <span class="text-danger" v-if="profile.lastname == ''">Last name is required</span>
          </template>
        </base-input>
        
        
      </div>

      <div class="form-group">
        <label>Email address</label>
        <div class="align-items-center">
          <span v-text="profile.email">name@company.com</span>
          
          <a class="actionprimary float-right" tabindex=3 v-on:click.prevent="trychangeemail(); openemailalert =false; setnewpassword = false " v-if="mode != 'emailchange' && !openemailalert " style = 'cursor: pointer'>Change email</a>

           <!--  <p v-if="emailchange.success">
              <br><br>
              <span class="text-success" >We've sent an email with a link to verify your ownership of the email address.<br><br>Didn't get it? <a class = "text-primary" href="#" @click.prevent="changeemail('resend')">Send email again.</a> <i class="fa fa-circle-o-notch fa-spin" v-if="emailchange.resendstatus=='working'"></i></span>
            </p> -->
          </div>
          <div v-if="openemailalert" > 
            
            <span class = "text-info mt-2" id="resetpasswordemailissend" style="cursor: pointer"> <i class ="far fa-exclamation-circle text-info"  ></i> Success! Please check your email.</span>
            
            <b-popover target="resetpasswordemailissend" title = "Change email"  custom-class= "info" id = "resetpasswordemailissendpopover" triggers="click" ref="popover" :show.sync="popoverShow" @show="onpopovershow" placement="top">
              <p class = "" >We've sent an email with a link to verify your ownership of the email address.<br><br>Didn't get it? <a class = "actionprimary" href="#" @click.prevent="changeemail('resend')">Send email again.</a> <i class="fa fa-circle-o-notch fa-spin" v-if="emailchange.resendstatus=='working'"></i></p>
            </b-popover>

          </div>
        </div>

        <div class="form-group clearfix" v-if="mode == 'emailchange'">
          
          <div class="float-right">
           <label >We'll send an email with a link to verify that you own the email.</label> 
           <input name="newemail" class="form-control" placeholder="New email"  onfocus="this.placeholder = ''" onblur="this.placeholder = 'New email'" type="text" v-model="emailchange.changetoemail" autocomplete="off">
           
           

           <span class="text-danger" v-if="emailchange.error == 'required'">Enter a valid email address.</span>
           <span class="text-danger" v-if="emailchange.error == 'inuse'">Email address is already in use.</span>
         </div>
       </div>

       <div class="form-group clearfix" v-if="mode == 'emailchange'">
        <div class="float-right">
          <div class="mt-1 text-right">
              <button-gruppe text1="Save" :loading = "emailchange.status=='working'" @no ="mode = 'idle'" @yes = "changeemail('send')"></button-gruppe>
          </div>
        </div>
      </div>
      
      <div class="form-group mb-4">
        <div class ="diverder"></div> 
        <h4>Sign-in options</h4>
        <div>Enable signing in using:</div>
        <div class="text-danger" v-if="errors.signinoptions == 'no'">Select a sign in option</div>
        <div class="control-label col-form-label" >

          <div class="d-flex align-items-center">
            <b-form-checkbox :disabled="emailsigninswitchdisable" v-model="emailswitch" inline name="check-button" class="sliders my-auto" id="signinemailswitch"  @change="togglesignin('email', $event ); "  size="lg" switch >
            </b-form-checkbox>

            <span class = "my-auto mr-auto" style= "padding-top:4px"> Email and password </span>

            <a v-bind:class="{ hide : setnewpassword == true || setnewpasswordrequired == true || profile.signinlocal == ''}"  tabindex=4 href="" class="actionprimary float-right" v-on:click.prevent="trychangepassword()" style= "padding-top:4px">Change password</a>
         </div>

       </div>
       
       <div class="clearfix">
        <div>

          <div class="" v-if="(setnewpassword || setnewpasswordrequired)">
            <div class="form-group" v-if="setnewpassword">
              <label>Current password</label>
              <input class="form-control hide" id="dummypassword" type="password">
              <input class="form-control" placeholder ="Change password" id="currentpassword" type="password" autocomplete="new-password" v-model="password.current" >
              <span class="text-danger" v-if="errors.currentpassword != ''" v-text="errors.currentpassword"></span>
            </div>


            <div class="form-group">
              <label>New password</label>
              <input class="form-control" placeholder ="New password"  id="newpassword" type="password" autocomplete="new-password" v-model="password.new">
              <span class="text-danger" v-if="errors.newpassword != ''" v-text="errors.newpassword"></span>
            </div>
            
            <popoverpassword target="newpassword" :check="password.new" :mobiledevise ="'topright'" />
            

            <div class="form-group">
              <label>Confirm new password</label>
              <input class="form-control" placeholder ="New password"  id="confirmpassword" type="password" autocomplete="new-password" v-model="password.new2">
              <span class="text-danger" v-if="errors.confirmpassword != ''" v-text="errors.confirmpassword"></span>
            </div>

            <div class="form-group text-right">
              <button-gruppe text1="Save" @no ="setnewpassword = false" @yes = "changepassword()"></button-gruppe>
              
            </div>
          </div>

          
        </div>
      </div>
      <div class=" mx-0  ">
        <div class ="control-label col-form-label ">

          <div class="d-flex align-items-center">
            <b-form-checkbox :disabled="googlesigninswitchdisable || googleswitch == false" v-model="googleswitch" inline class="sliders my-auto " :class="{'googleswithcoff': !googleswitch}" id="signingoogleswitch" name="signingoogle" @change="togglesignin('google', '' ); "  size="lg" switch >        
            </b-form-checkbox>
            <span  class = "my-auto mr-auto"  style= "padding-top:4px">
              Google  <template v-if="googleswitch"> ({{profile.signingoogle }}) </template>
            </span>

            <a v-bind:class="{hide:googleswitch==true}" tabindex=6 href="" class="actionprimary float-right" v-on:click.prevent="clicksignind()" style= "padding-top:4px">Connect Google account</a>
          </div>
      </div>
      <!-- <toggle-button class="my-auto" id="signingoogleswitch" name="signingoogle" :sync="true" :color="{checked: 'rgb(46, 71, 123)', unchecked: 'rgb(255, 255, 255)', disabled:'rgb(46, 71, 123)'}" :value="googleswitch" @change="togglesignin('google', $event )" :disabled="googlesigninswitchdisable"/>  -->
      <div style ="margin-top: 4px; display: none;" class = "col-md-10 col-12 px-0 ">

          
        
        <GoogleLogin id="signinwithgoogle" :params="googleparams" :onSuccess="gOnsuccess" :onFailure="gOnfailure" class="btn  btn-google float-right">  <img src="@/static/img/glogo.png" style="width: 24px; height: 24px;"> Connect Google account </GoogleLogin>
      </div>
    </div> 

    <p>&nbsp;</p>
    <div class="form-group mb-4" v-if="profile.signinmethods && profile.signinmethods.length>0">
        <div class ="diverder"></div> 
        <h4 >SSO Sign-in</h4>

        <div v-for="( i,o ) in profile.signinmethods" :key="o">
        <p> SSO sign-in with {{ i.email }} is currently enabled via the {{ i.idp }} organization </p>
      </div>
    </div>


    <p v-if="1==2">&nbsp;</p>
    <div v-if="1==2" class="form-group mb-4">
        <div class ="diverder"></div> 
        <h4>Integrations</h4>

        <div class="mb-2">
         <jirasignin></jirasignin>
        </div>
    </div>


    

    
    
  

    <span class="text-danger ml-2 float-left" v-if="googlesigninfailed" >That Google account is already in use. Please select a different Google account.</span>



  </div>

  
 <!--  <div class ="diverder"></div>
     <div class="form-group">
      <b-button variant="secondary " @click='signout()' class=""> <i class = "far fa-sign-out mr-2" />Sign out</b-button>
    </div> -->
<div class ="diverder"></div>
  <p class="">
    <router-link :to="'/' + orgkey + '/deleteaccount'" class = "actionprimary">Click here to permanently delete your account</router-link>

  </p>

</div>
</main>
</div>
</template>
<style type="text/css">
  
  
@media(max-width: $breakpoints-sm){
/*    .sliders{
      margin-right: 0;
      margin-bottom: -21px !important;
    }*/
  }

@media(min-width: $breakpoints-md){
  /*  .sliders{
      margin-right: 0;
      margin-bottom: -12px !important;
    }*/
  }

</style>

<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
  @import'@/scss/abstracts/mixin.scss';
.imgloader{
  height: 150px;
  width: 150px;
  display: flex;
  i{
    color: $N800;
  }

}



#myprofilepages ::v-deep{

}

@include media-max-width($breakpoints-sm, $breakpoints-md){

  
  #signinwithgoogle{
    float:none !important;
  }
  
 
}


</style>
<script type="text/javascript"></script>
<script>
  import Emailapi from '@/services/api/emails'
  import Userapi from '@/services/api/users'
  import Dashboardapi from '@/services/api/dashboard'
  import popoverpassword from '@/views/auth/popoverpassword'
  import avatar from '@/views/tools/avatar'

  import AWS from 'aws-sdk'
  import fs from 'fs'  
  import {bus} from '@/main'
  import {TopBar} from '@/components/topbar'
  import GoogleLogin from 'vue-google-login';


  import jirasignin from '@/views/tools/jirasignin'

  const ID = process.env.VUE_APP_CLIENT;
  const SECRET = process.env.VUE_APP_CRET;

  // The name of the bucket that you have created
  const BUCKET_NAME = 'flowe-editor';
  AWS.config.update({region: 'eu-west-3'});

  const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
    apiVersion: '2006-03-01', 
    region: 'eu-west-3'
  });

  //changes

  export default{
    name:'mainprofile',
    props:{

    },
    computed : {

      orgkey(){
        return this.$route.params.orgname
      },

      token(){
        return localStorage.token
      },

      userinfo(){
        return this.$store.getters.user
      },

      isMobile(){
        return this.$store.state.isMobile
      },

      passwordisset(){

       var hasapassword = false

       if( this.profile.credential && this.profile.credential.password != undefined && this.profile.credential.password != ''  ){
        hasapassword = true
       }

      return hasapassword
    },

      emailsigninswitchdisable(){

        var s = false

        if( this.switchstatus == "working" || ( this.googleswitch == false && this.emailswitch != false ) ){
          s = true
        }else if( this.googleswitch == false && this.emailswitch == false ){
          s = false
        }else{
          s = false
        }

        return s
      },
      googlesigninswitchdisable(){

        var s = false

        if( this.switchstatus == "working" || this.emailswitch == false ){
          s = true
        }else{
          s = false
        }
        
        return s

      },

    },
    data(){
     return{
       emailswitch : false,
       googleswitch : false,
       signinmethods : [],
       profile:{},
       profilepic : "",
       commited : true,
       signinwith : "",          
       gapiloaded : false,
       userstate : 0,
       signinemail:false,
       signinoauth:false,
       signingoogle : false,
       signingoogleemail : "",
       connectedas : "",
       signinmethods : 0,
       errors: [],
       passwordreset: '',
       googlesignininuse : false,
       modalisopen : false,
       mode : "idle",
       emailchangeerror : "",
       changetoemail : "",
       emailsending : false,
       emailresending : false,
       imageuploading : false,
       emailid : "",
       changepassowrd : true,
       setnewpassword : false,
       setnewpasswordrequired : false,
       googlesignbuttonclicked : false,
       googlesigninfailed :"",
       accountchangeddetails : {"passwordhaschanged":false,"signinlocalchanged":false,"signinlocalnewvalue":"","signingoolechanged":false, "signingoolenewvalue":"", "emailhaschanged":false, "emailhasbeensent":false},
       password : { current:"", new:"", new2:"" },
       showimagehint : false,
       uploaderror : "",
       working : false,
       inlinesave : "idle",
       mailsendid : "",
       uploadedsize : 0,

       profileupdateconfirm : 0,
       emailhasbeebresendt: false,
       emailsigninswitch : false,
       googlesigninswitch : false,
       switchstatus : "idle",

       emailhasbeensent: false,


       passwordchange : { status:"idle" , error:"", message:"", success:false  },
       emailchange : { changetoemail:"", error:"", message:"", success:false, status:"",resendstatus:"" },

       formscrolloptions:{
        height:''
      },
      selectedfile : null,
      profiledata : { status:"idle", "updated":false, success:false, update:"" },

      dismissSecs: 3,
      openemailalert: false,

      popupdisabled:false,

      croppingimg : "",
      cropping : false,
      googleparams: {
        client_id: "957046757712-8onubrnl55fk21ihr4m5hd243fcbkaq2.apps.googleusercontent.com",
        //client_id: "831364380990-6fl4ir88p2un0ftd5p7o2rskugmj3963.apps.googleusercontent.com",
        class : "btn btn-block btn-lg btn-google"
      },

      popoverShow: false,


    }
  },
  components:{
    popoverpassword, GoogleLogin, TopBar, avatar, jirasignin
  },

  watch:{
    popupdisabled : function(e){
      console.log(e)
    },
    setnewpassword: function(e){
      this.errors = {}
    },
    mode: function(e){
      if(e == 'idle' ){
        // this.emailchange = {}
      }
    }
  },
  mounted(){

    $('#preloader').addClass('hide');
    this.profile = Object.assign({}, this.userinfo)

    bus.$off('onshowmodal')
    bus.$off('modalhidden')
    bus.$off('hideModal')

    bus.$on('onshowmodal',this.onshowmodal)
    bus.$on('modalhidden',this.modalhidden)
    bus.$on('hideModal',this.hideModal)

    if( this.isMobile ){
      $('[data-toggle="tooltip"]').tooltip({delay:{show:500, hide:0}})
    }
    
    this.emailswitch = this.profile.signinlocal == 'email'
    this.googleswitch = this.profile.signingoogle == "" ? false : true
    document.title ='Profile | Flowe'
    /*emailswitch(){
        return 
      },

      googleswitch(){
        
      },*/
    }, 

    methods: {
      clicksignind(){
        $('#signinwithgoogle').click()
      },
      signout(){
        let self = this
        this.$store.dispatch('doSignOut')
      },

      countDownChanged(dismissCountDown) {
        this.profileupdateconfirm = dismissCountDown
      },

      doupdate( obj, ev ){
        let self = this
      
        if( obj == 'firstname' && this.profile.firstname != '' ){

          this.updateprofile( 'firstname', this.profile.firstname, function(){
            self.$store.commit('SET_USER_FIRST_NAME', self.profile.firstname)
           
          })


        } else if( obj == 'lastname' && this.profile.lastname != ''){

          this.updateprofile( 'lastname',this.profile.lastname, function(){
            self.$store.commit('SET_USER_LAST_NAME',self.profile.lastname)

              
          })
        }
      
        if( obj == 'firstname' || obj == 'lastname' ){

        //  this.updateprofile( 'sortstring', this.profile.firstname.toLowerCase()+this.profile.lastname.toLowerCase()+this.profile.email.toLowerCase(), function(){})
        //  this.updateprofile( 'searchstring', this.profile.firstname.toLowerCase()+" "+this.profile.lastname.toLowerCase()+" "+this.profile.firstname.toLowerCase(), function(){})

        }

       
      },

      togglesignin( mode , signinnewvalue ){

        this.profiledata.success = false

        let self = this

        var newvalue = ""
        
// && this.googleswitch == true
        if( mode == 'email'  ){

          this.switchstatus = "working"

          newvalue = this.profile.signinlocal == 'email' ? "" : "email"

          if( (newvalue == "email" && !this.passwordisset) ){
            this.setnewpasswordrequired = true
          }

          if( (newvalue == "email" && this.passwordisset) || newvalue == "" ){
            
            this.updateprofile( "signinlocal", newvalue, function( isok ){

              if( isok ){

                self.profile.signinlocal = newvalue
                self.switchstatus = "idle"
                self.profileupdateconfirm = self.dismissSecs

                self.profiledata.success = true
                self.setnewpassword = false
                if( newvalue == "" ){
                  self.profiledata.update = "emailpassworddisabled"
                  var str = 'You\'ve disabled signing in using your email address and password.'

                bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true})

                // self.popToast(true,'You\'ve disabled signing in using your email address and password.')
                
                Emailapi.Emailpassworddisabled( self.token )

                self.$store.dispatch('updateUserDetails', { "field": 'signinemail' , "value":"" } )

              }else{
                self.profiledata.update = "emailpasswordenabled"
                var str = 'Success! You\'ve enabled signing in using your email and the password you\'ve entered.'

                bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true})
                Emailapi.Emailpasswordenabled( self.token )

                self.$store.dispatch('updateUserDetails', { "field": 'signinemail' , "value":"email" } )
                
              }
            
            }else{



              if( signinnewvalue == "" ){
                self.emailswitch =  true
              }else{
                self.emailswitch =  false
              }

            }

          })

          }


        }

// && this.emailswitch == true
        if( mode == 'google' ){

          this.switchstatus = "working"

          newvalue = signinnewvalue
          
          this.updateprofile( "signingoogle", newvalue, function( isok ){

            //alert( newvalue )

            if( isok ){

              self.profile.signingoogle = newvalue
              self.switchstatus = "idle"

              self.profileupdateconfirm = self.dismissSecs
              self.profiledata.success = true

              if( newvalue == "" ){
                self.profiledata.update = "googledisabled"
                self.googleswitch =  false
                var str = 'You\'ve successfully disabled signing in using your Google account.'

                self.$store.dispatch('updateUserDetails', { "field": 'signingoogle' , "value":"" } )


                bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true}) 
                Emailapi.Googlesignindisabled( self.token )
              }else{
                self.profiledata.update = "googleenabled"
                self.googleswitch = true
                 var str = 'Success! You\'ve enabled signing in using your Google account.'

             bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true})
                Emailapi.Googlesigninenabled( self.token )
              }

            }else{

              if( signinnewvalue == "" ){
                self.googleswitch =  true
              }else{
                self.googleswitch =  false
              }
              //console.log( signinnewvalue , "vvv")
              
            }

          })


        }


      },

      updateprofile( tag, newvalue, callback ){

        let self = this

        this.profiledata.status = "saving"

        Userapi.Updateprofile( { "userid":this.profile._id, "field":tag, "value":newvalue, "t":this.token } )
        .then( function(response){

          if(response.data.ok ){
            self.profiledata.updated = true
            if( callback ){
              callback( true )
            }
           //self.toast(true,'You\'ve successfully upload a new profileimage')
         }else{

          if( response.data.req && response.data.req == "password" ){
            self.setnewpasswordrequired = true
            self.setnewpassword = false
            self.trychangepassword()
          }else{
            callback( false )
          }

          
         }
         

       })
        .catch(error => console.log(error))
        .finally( () => {
          self.profiledata.status = "idle"

        })

      },

      fileclicked(){

        $('[data-toggle="tooltip"]').tooltip('hide')

      },

      onfileselected( event ){

        var file = event.target.files[0];      
              
        let formData = new FormData();
        formData.append('file', file);  

        var validfiletype = false

        var newfile = formData.get('file');

        var ext = ""

        var uploadfailed = 0

        if( newfile.type.indexOf("jpeg") > -1 ){
          validfiletype = true
          ext = ".jpeg"
        }
        else if( newfile.type.indexOf("png") > -1 ){
          validfiletype = true
          ext = ".png"
        }
        else if( newfile.type.indexOf("gif") > -1 ){
          validfiletype = true
          ext = ".gif"
        }else{
          uploadfailed++
          validfiletype = false
        }

        if( validfiletype ){

        $('[data-toggle="tooltip"]').tooltip('hide')

        this.uploaderror = ''

        this.cropping = true

        var md5 = require('md5');

        let self = this
        //let formData = new FormData();
        //formData.append('file', event.target.files[0]);

        this.profiledata.profilepicupdate = false
        this.profiledata.status = "uploading"     

        //var newfile = formData.get('file');
        
        var fr = new FileReader();


        var image = new Image();

        image.onerror = function() {
          self.uploaderror = 'invalid'
          self.cropping = false
        };

      image.onload = function() {

        console.log('image onload')

          if(this.width) {
            /**/

            fr.onload = function( e ){

              self.croppingimg = e.target.result; 




              if( uploadfailed == 0 ){

                var nowstring = new Date().toString()
                
                var newfilename = md5(newfile.name + nowstring) + ext

                var bucketpath = "https://s3-eu-west-3.amazonaws.com/flowe-editor/"

                
                const params = {
                  Bucket: 'flowe-editor',
                  ACL : 'public-read',
                    Key:  newfilename, // File name you want to save as in S3
                    Body: file
                  };

                self.profile.profilepic = ""

                s3.upload(params, function(err, data) {

                  if (err) {
                    throw err;
                  }

                $.getJSON( "https://profilepictures.flowe.io/?resize=yes&i=" + newfilename, function(data){
                  //self.froalaconfig.imageUploadToS3 = data

                  if( data.ok ){

                    var newlocation = bucketpath + data.filename
                    self.profile.profilepic =  newlocation

                    self.cropping = false

                    self.updateprofile( "profilepic", newlocation, function(){

                      //console.log( self.userinfo ,'before')
                      self.$store.dispatch('updateProfilepicture', newlocation)
                      //console.log( self.userinfo ,'after')

                      $('[data-toggle="tooltip"]').tooltip('hide')

                    })


                  }
                  //console.log( data, "onupload")
                }).fail(function() {
                  self.cropping = false
                  self.uploaderror = 'uploadfailed'
                })              

              //console.log(`File uploaded successfully. ${data.Location}`);
            });

            }else{
              self.cropping = false
              self.uploaderror = 'invalid'

            }

            }
            fr.readAsDataURL(file);

/***/


          }else{
            self.uploaderror = 'invalid'
            self.cropping = false
          }
      };

      // load the file into an image to check if its an actual image
      image.src = URL.createObjectURL(file);


        }else{
          this.uploaderror = 'invalid'
        }
      },

      trychangeemail(){

        this.mode = 'emailchange'
        this.emailchange.changetoemail = ""
        this.emailchange.success = false
        
        this.$nextTick(function(){
          setTimeout( function(){
            console.log( $('input[name="newemail"]') )
            $('input[name="newemail"]')[0].focus()
          }, 10)
          
        })
      },

      changeemail : function( isresend ){

        if( isresend == "resend" ){
          this.emailchange.resendstatus = 'working'
        }else{
          this.emailchange.status = "working"
        }

        this.emailchange.error = ""

        let self = this


        Userapi.Requestchangeofemail( this.profile._id, this.emailchange.changetoemail, this.token )
        .then( function(response){

          if(response.data.ok ){

            self.emailchange.success = true
            self.mode = "idle"
            if( isresend == "resend" ){
              self.emailhasbeebresendt = true
            }
            
            if(isresend == "send"){
              self.openemailalert = true
            }

          }else{
            self.emailchange.error = response.data.message
            
          }
          

        })
        .catch(error => console.log(error))
        .finally( () => {
          self.emailchange.status = "idle"
          self.emailchange.resendstatus = "idle"

        })

      },

      trychangepassword : function(){

        this.setnewpassword = true; 
        this.mode= 'idle'
        this.password.current  =''
        this.password.new = ''
        this.password.new2 = ''
        this.errors.currentpassword  = ''


        setTimeout( function(){

          if( $('#currentpassword')[0] ){
            $('#currentpassword')[0].focus()
          }else{
            $('#newpassword')[0].focus()
          }
          
          
        }, 100)
      },

      changepassword : function(){


        let self = this

        Userapi.Changepassword( { "auth":this.profile._id, "currentpassword":this.password.current, "newpassword":this.password.new, "confirmpassword":this.password.new2  } )
        .then( function(response){

          if(response.data.ok ){

            self.profile.credential = "setanewpassword"
            self.profile.signinlocal = "email"

            self.profileupdateconfirm = 3
            self.passwordchange.success = true
            self.setnewpassword = false
            

            if(self.setnewpasswordrequired){
              var str = 'Success! You\'ve enabled signing in using your email and password.'

             bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true})
              Emailapi.Emailpasswordenabled(self.token)
              self.setnewpasswordrequired = false

            } else {              
              var str = 'Success! You\'ve updated your password.'
              Emailapi.Passwordchanged( self.token )
              bus.$emit('craeteaToast', {content:str,title:'Change is saved', variant:'success',append:true})


            }

            //self.setnewpasswordrequired = false
          }else{
            self.errors = response.data.errors
          }

        })
        .catch(error => console.log(error))
        .finally( () => {
        })


      },

      resendemail : function( uid, logid ){


      },

      gOnsuccess : function( googleUser ){

        

        /////updateUserDetails
            //togglesignin('google', $event )

            let self = this

              //var profile = googleUser.getBasicProfile();      
              var id_token = googleUser.getAuthResponse().id_token;
              
              Dashboardapi.Askgoogletoken( id_token )
              .then( function(response){

               Dashboardapi.Enablegooglesignin( response.data.aud, response.data.email, self.token )
               .then( function(response){

                if( response.data.ok ){

                  self.profile.signingoogle = response.data.email
                  self.togglesignin('google', response.data.email )


                  self.$store.dispatch('updateUserDetails', { "field": 'signingoogle' , "value":response.data.email } )


                }else{
                  var bool = response.data.user && response.data.user._id != '' && (response.data.user._id != response.data.meprofile._id) 
                  var bool2 = response.data.user2 && response.data.user2._id != '' && (response.data.user2._id != response.data.meprofile._id) 
                  if( bool || bool2){
                    var str = 'The Google account is connected to another Flowe account. To connect it to this account you will first need to sign in using the Google account, and then disconnect it from the other Flowe account.'
                    var str1 = "Google account is already in use"
                    bus.$emit('craeteaToast', {content:str,title:str1, variant:'danger',append:true,sup:''})
                  }else{
                    var str = ' We were unable to complete this action. Please try again later or contact us if the problem persists.'
                    var str1 = "Error connecting Google account"
                    bus.$emit('craeteaToast', {content:str,title:str1, variant:'danger',append:true,sup:''})


                  }

                  
                }
                
                
              })

             })

             /* Signinapi.Signinwithgoogle( id_token )
              .then( function(response){

                if( response.data.ok ){

                  console.log( response.data )

                  //self.usetoken( response.data.response )    
                  
                }else{
                  self.nogooglesignin = true
                }

              })
              .catch(error => console.log(error))
              .finally( () => {

                self.status = 'idle'

              })*/



            },

            gOnfailure : function( rep ){

              console.log( rep, "error")

            },

            onpopovershow(){
              let self = this

              $('html').on('click',function() {
                self.popoverShow = false
              });
              
              this.$nextTick(function(){
                $('#resetpasswordemailissendpopover').on('click', function(event){
                  event.stopPropagation();
                })
              })
            }, 

        }
      }
    </script>








