<template>

	  <span>
	  	<div class="p-2" v-if="!hastoken"><button class="btn btn-primary" @click="getjira">Connect to Jira</button></div>
	  	<div class="p-2" v-else><button class="btn btn-primary" @click="removejira">Disonnect Jira</button></div>
	  </span>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	//import Trelloapi from '@/services/api/trello'

	import {bus} from '@/main'
	import axios from 'axios'

	import Jiraapi from '@/services/api/jira'


	export default{
		name:'jiraclassic',

		props:{
		},

		data(){
			return{
			}
		},

		watch:{
			
		},
		mounted(){
			window.opener1=this;
			this.validatetoken()
		},
		methods:{


			popup(url, title, width, height) {

        var left = (screen.width / 2) - (width / 2);
        var top = (screen.height / 2) - (height / 2);
        var options = '';    
        options += ',width=' + width;
        options += ',height=' + height;
        options += ',top=' + top;
        options += ',left=' + left;    
        return window.open(url, title, options);

    	},

			validatetoken( callback ){

				console.log( "validating")

				let self = this


				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				if( !localStorage.jiratoken ){

					console.log( "no token")
					return false
				}

				axios.get( "https://api.atlassian.com/oauth/token/accessible-resources", headers  ).then( function( response ){

					console.log( "no token")

       				//console.log( response.data, "jiratoken")
       				
       				//let requestingurl = localStorage.requestingurl

       				//if( !requestingurl ){
       				//	requestingurl = "/"
       				//}
					    localStorage.jiraprofile =  JSON.stringify(response.data)
       				self.jiraprofile = JSON.parse(localStorage.jiraprofile)
       				//self.jiraprofile 


   		 		}).then( function(){

   		 				self.jiraprofile.forEach(function (element) {

   		 					if( self.cockpit.connect?.jira.jiraprofileid == element.id ){
   		 						self.selectedjiraprofile = element
   		 						self.selectedjirasite = element.url.replace('https://','')
   		 						//self.selectedjiraproject 

   		 						self.getprojects( element )


   		 					}

   		 				})

   		 				if( callback ){
   		 					callback()
   		 				}

   		 				

   		 		}).catch( function(  error ){

   		 			if( error.response.status == 401 ){
   		 				localStorage.jiratoken = ""
   		 			}

   		 			console.log( error.response.status,  "er")
   		 			//localStorage.jiratoken = ""
   		 			//self.getjira()
   		 		})


			},

			getjira(){
				

				//window.open( this.jiralocal())

				localStorage.requestingurl = window.location.href
				this.authwindow = this.popup( "/apitoken?loading="+ encodeURIComponent(this.jiraurl)+"&redirto="+this.jiraredirecturl, 'Open Jira', 600, 400)
				///location.href = this.jiraurl

			},

		},
		computed:{


			hastoken(){

				let jiratoken = localStorage.jiratoken

				if( !localStorage.jiratoken ){
					return false
				}else{
					return true
				}

			},

			jiraredirecturl(){


				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				let local = "http%3A%2F%2Flocalhost%3A8080%2Fapitoken&state=Qddib53BT3khZ22krZTxBnW2"

				let test = "https%3A%2F%2Fdev2.flowe.io%2Fapitoken&state=vVFvdqV1K5b9uN6YNJMpdRlX"

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn

			},

			jiraurl(){

				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				let local = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&response_type=code&prompt=consent&redirect_uri="

				let test = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=OrTRI9oWH962QAmvpccB3Y31aCvv8gFk&scope=read%3Ajira-work%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&response_type=code&prompt=consent&redirect_uri="

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn
			},

			localjiratoken(){
				return localStorage.jiratoken
			},
			cockpitid(){
        		return this.$route.params.cid
     		},
			orgkey(){
		    	return this.$route.params.orgname ? this.$route.params.orgname : null
		  	},	
			token(){
				return this.$store.getters.token
			},
      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{},

	}
	//  
</script>